import React, {Component} from 'react';
import {
    Button,
    Modal,
    Col,
    CardBody,
    Card,
    ModalHeader,
    ModalBody,
    ModalFooter,
    DropdownMenu,
    Form,
    FormGroup,
    DropdownItem,
    DropdownToggle,
    UncontrolledButtonDropdown,
    Label,
    Input,
} from 'reactstrap';
import 'react-dropzone-uploader/dist/styles.css'

import moment from 'moment';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'
import AddCallNotes from '../Accounts/AddCallNotes';
import 'lity/dist/lity.min.js';
import 'lity/dist/lity.min.css';

const {forwardRef, useRef, useImperativeHandle} = React;

class CallNotes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal_edit: false,
            loading: true,
            trigger_note: false,
            calls_notes: [],
            call: {
                start1: new Date(),
                end1: new Date(),
                notes: "",
                values: [],
                responses: {},
                reminders: [],
            }
        };
        this.getCalls = this.getCalls.bind(this);
    }

    componentDidMount() {
        this.getCalls()
        if (typeof this.props.selected_call_id != "undefined") {
            console.log(this.props.selected_call_id)
            this.goToCall(this.props.selected_call_id)
        }
    }

    deleteNote = (call_id) => {
        var self = this;
        axios.delete(API_ROOT + '/call_notes/' + self.props.account_id + "/" + call_id)
            .then(function (response) {
                self.getCalls();
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        calendarEvents: [],
                        loading: false
                    });
                }
            });
    };

    resetCall = () => {
        this.setState({
            trigger_note: false,
        });
    };

    goToCall = (call_id, element) => {
        if (typeof element == "undefined" || typeof element.target == "undefined" || !element.target.classList.contains("noclick")) {
            this.setState({
                trigger_note: call_id,
            });
        }
    };

    getCalls() {
        this.setState({
            loading: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        axios.get(API_ROOT + '/account/' + self.props.account_id + '/call_notes')
            .then(function (response) {
                self.setState({
                    calls_notes: response.data,
                    loading: false
                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        calendarEvents: [],
                        loading: false
                    });
                }
            });
    }

    toggle = () => {
        this.setState({
            modal_edit: !this.state.modal_edit
        });
    };


    render() {
        return (
            <div>
                <Card className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <CardBody>
                        <div className="lead bb">{(this.state.calls_notes.length == 0 ? '' : this.state.calls_notes.length)} Call Notes
                            <AddCallNotes account_id={this.props.account_id} account={this.props.account} getCalls={this.getCalls} trigger_note={this.state.trigger_note}
                                          resetCall={this.resetCall}/>
                        </div>
                        <div className="table-responsive">
                            <table className={(this.state.calls_notes.length > 0 && !this.state.loading ? "table table-hover table-pointer text-left" : "d-none")}>
                                <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Start</th>
                                    <th className="d-none d-md-table-cell">End</th>
                                    <th className="d-none d-md-table-cell">By</th>
                                    <th>Status</th>
                                    <th className="d-none d-md-table-cell">Photos</th>
                                    <th className="d-none d-md-table-cell">Reminders</th>
                                </tr>
                                </thead>
                                <tbody>
                                {this.state.calls_notes.map(function (call, key) {
                                    var photos = []
                                    if (call.photos != null) {
                                        photos = call.photos.split(',')
                                    }
                                    return (
                                        <tr key={key} onClick={this.goToCall.bind(this, call.call_id)}>
                                            <td>{moment(call.start).format("MMM D, YYYY")}</td>
                                            <td>{moment(call.start).format("HH:mm A")}</td>
                                            <td className="d-none d-md-table-cell">{moment(call.end).format("HH:mm A")}</td>
                                            <td className="d-none d-md-table-cell">{(typeof this.props.staff[call.staff_id] != "undefined" ? this.props.staff[call.staff_id].first_name : "")}</td>
                                            <td><i className={(call.editable ? "fas fa-lock-open" : "fas fa-lock")}></i></td>
                                            <td className="d-none d-sm-table-cell noclick">
                                                {photos.map(function (photo, key) {
                                                    return (
                                                        <a href={"call_photos/" + photos[key] + ".jpg"} className="mr-1 noclick" data-lity>
                                                            <img src={"call_photos/" + photos[key] + "_sm.jpg"} className="noclick"
                                                                 style={{width: "auto", maxHeight: "30px"}}></img>
                                                        </a>
                                                    )
                                                }, this)}
                                            </td>
                                            <td>{(call.reminders > 0 ? call.reminders + "x" : "")} <i className={(call.reminders > 0 ? "far fa-clock" : "d-none")}></i></td>
                                        </tr>
                                    )
                                }, this)}
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>

            </div>
        );
    };
}

export default (CallNotes);
