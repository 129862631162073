import React from 'react';
import ContentWrapper from '../Layout/ContentWrapper';
import {Button, DropdownItem, DropdownToggle, DropdownMenu, UncontrolledButtonDropdown} from 'reactstrap';
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import {Link} from 'react-router-dom';
import {getInventorySources} from '../Inventory/InventoryFunctions.js';
import {getFactors} from "../Common/Factors";
import {getStaff} from '../Staff/StaffFunctions.js';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.css';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import 'bootstrap-daterangepicker/daterangepicker.css';
import moment from 'moment';
import 'moment-timezone';
import {getDeliveryDate} from "../Accounts/AccountFunctions";

moment.tz.setDefault("America/Toronto");

class KegTransactions extends React.Component {
    constructor(props) {
        super(props);

        this.changeProduct = this.changeProduct.bind(this);
        this.updateDates = this.updateDates.bind(this);

        this.state = {
            loading: true,
            // default search settings
            factor_id: null,
            // default search filter names
            factor_name: "All",
            action_types: {0: "Write-Off (Keep Deposit)", 1: "Pickup (Return Deposit)"},
            inventory_sources: [],
            factors: [],
            returns: [],
            staff: [],
            startDate: (localStorage.getItem('startDate') != null ? moment(localStorage.getItem('startDate')) : moment().subtract(29, 'days')),
            endDate: (localStorage.getItem('endDate') != null ? moment(localStorage.getItem('endDate')) : moment().endOf('day')),
            ranges: {
                'Today': [moment(), moment()],
                'Last 7 Days': [moment().subtract(6, 'days'), moment().endOf('day')],
                'Last 30 Days': [moment().subtract(29, 'days'), moment().endOf('day')],
                'Last Week': [moment().subtract(1, 'week').startOf('week'), moment().subtract(1, 'week').endOf('week')],
                'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
                'All Time': [moment("01 Jun 2018"), moment().endOf('day')]
            }
        };
    }

    componentDidMount() {
        document.title = "Keg Return Transactions | Equals Brewing";
        var self = this;
        getInventorySources(null, function (inventory_sources) {
            getFactors(function (factors) {
                getStaff(function (staff) {
                    self.setState({
                        inventory_sources: inventory_sources,
                        factors: factors,
                        staff: staff,
                    });
                    self.updateReturns();
                });
            });
        });
    }

    updateReturns() {
        var self = this;
        axios.defaults.withCredentials = true;
        axios.post(API_ROOT + '/kegs/transactions', this.state)
            .then(function (response) {
                self.setState({
                    returns: response.data,
                    loading: false

                });
            })
            .catch(function (error) {
                if (error.response.status === 401) {
                    self.props.userSignOut()
                } else {
                    Swal("Error", error.response.data.Message, "error");
                    self.setState({
                        orders: [],
                        loading: false
                    });
                }
            });
    }

    changeProduct = (factor_id) => {
        if (factor_id == null) {
            var factor_name = "All"
        } else {
            var factor_name = this.state.factors[factor_id].name
        }
        this.setState({
            factor_id: factor_id,
            factor_name: factor_name
        }, () => {
            this.updateReturns();
        });
    };

    // on date range change
    updateDates(event, picker) {
        var self = this;
        if (event.type == "apply") {
            localStorage.setItem('startDate', picker.startDate);
            localStorage.setItem('endDate', picker.endDate);
            this.setState({
                startDate: picker.startDate,
                endDate: picker.endDate
            }, () => {
                this.updateReturns();
            });
        }
    }

    export = () => {
        var csv = 'Account #, License #, Name, Address, City, Size, Quantity, By, date\n';
        for (var i = 0; i < this.state.returns.length; i++) {
            var keg_return = this.state.returns[i];
            csv += '"' + keg_return.account_id + '","' + keg_return.license_num + '","' + keg_return.name + '","' + keg_return.address1 + '","' + keg_return.city + '","' + this.state.factors[keg_return.factor_id].name + '","' + keg_return.quantity + '","' + this.state.staff[keg_return.staff_id].first_name + '","' + moment(keg_return.datetime).format("MMM D hh:mm A") + '"\n';
        }
        const blob = new Blob([csv], {type: "text/csv"});
        const url = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.download = "Keg Returns.csv";
        link.href = url;
        link.click();
    }

    render() {
        var total_quantity = parseFloat(0);
        let start = this.state.startDate.format('MMM. D/YY');
        let end = this.state.endDate.format('MMM. D/YY');
        let label = start + ' - ' + end;
        if (start === end) {
            label = start;
        }

        return (
            <ContentWrapper>
                <div className="content-heading">
                    Keg Return Transactions
                </div>
                <div className="mb-3 form-inline">
                    <UncontrolledButtonDropdown className="m-1">
                        <DropdownToggle caret color="primary" style={{width: 'auto'}}>
                            Size: {this.state.factor_name}
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem key="null" onClick={() => this.changeProduct(null)}>All</DropdownItem>
                            <DropdownItem divider/>
                            {Object.keys(this.state.factors).map((factor_id) =>
                                <DropdownItem className={(this.state.factors[factor_id].factor_id == 1 ? "" : "")} key={factor_id}
                                              onClick={() => this.changeProduct(factor_id)}>{this.state.factors[factor_id].name}</DropdownItem>
                            )}
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                    <DateRangePicker
                        startDate={this.state.startDate}
                        endDate={this.state.endDate}
                        maxDate={moment()}
                        ranges={this.state.ranges}
                        onEvent={this.updateDates}
                        autoUpdateInput={true}
                    >
                        <Button className="selected-date-range-btn" color="primary">
                            Period: <b>{label}</b> <span className="fas fa-caret-down fa-sm"></span>
                        </Button>
                    </DateRangePicker>
                </div>
                <div className={(this.state.loading ? "card card-default whirl traditional" : "card card-default")}>
                    <div className={(this.state.loading ? "invisible" : "")}>
                        <div className="card-header">
                            <div className="btn btn-secondary btn-xs float-right mr-1" onClick={this.export}>
                                <i className="fas fa-download"></i> Export
                            </div>
                            <div className="card-title">{this.state.returns.length} Returns</div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                <table className={(this.state.returns.length > 0 && !this.state.loading ? "table table-hover" : "d-none")}>
                                    <thead>
                                    <tr>
                                        <th className="d-none d-xl-table-cell">License #</th>
                                        <th>Account</th>
                                        <th>Address</th>
                                        <th>City</th>
                                        <th>Size</th>
                                        <th className="text-right">Quantity</th>
                                        <th className="d-none d-sm-table-cell">by</th>
                                        <th className="d-none d-sm-table-cell">Date</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {this.state.returns.map(function (keg_return, key) {
                                        total_quantity = total_quantity + keg_return.quantity;
                                        return (
                                            <tr key={key}>
                                                <td className="d-none d-xl-table-cell">{keg_return.license_num}</td>
                                                <td><Link to={"../account/" + keg_return.account_id}>{keg_return.name}</Link></td>
                                                <td>{keg_return.address1}</td>
                                                <td>{keg_return.city}</td>
                                                <td>{this.state.factors[keg_return.factor_id].name}</td>
                                                <td className="text-right">{keg_return.quantity}</td>
                                                <td className="d-none d-sm-table-cell">{this.state.staff[keg_return.staff_id].first_name}</td>
                                                <td className="d-none d-sm-table-cell">{moment(keg_return.datetime).format("MMM D hh:mm A")}</td>
                                            </tr>
                                        )
                                    }, this)}
                                    </tbody>
                                    <tfoot>
                                    <tr>
                                        <th colSpan="5" className="text-right">Total Returned:</th>
                                        <th className="text-right">{total_quantity}</th>
                                        <th colSpan="2"></th>
                                    </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        );
    }
}

export default (KegTransactions);
