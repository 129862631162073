import React from "react";
import axios from 'axios';
import {API_ROOT} from '../../api-config';

export function getFactors(response) {
    axios.defaults.withCredentials = true;
    axios.get(`${API_ROOT}/factors/`, {withCredentials: true})
        .then(function (ajax_response) {
            response(ajax_response.data);
        })
        .catch(function (error) {
            response({});
        });
}

