import axios from 'axios';
import {API_ROOT} from '../../api-config';


export function getInventorySources(filters, response) {
    var fetch_data = true;
    var current_timestamp = Math.round((new Date()).getTime() / 1000);
    if (localStorage.getItem('inventory_sources') !== null) {
        var inventory_sources = JSON.parse(localStorage.getItem('inventory_sources'));
        if (inventory_sources.date_fetched > (current_timestamp - (60 * 10))) {
            fetch_data = false;
            filter(filters, inventory_sources.data);
        }
    }
    if (fetch_data) {
        axios.defaults.withCredentials = true;
        axios.get(`${API_ROOT}/inventory/sources`, {withCredentials: true})
            .then(function (ajax_response) {
                var inventory_sources = {};
                inventory_sources.data = {};
                inventory_sources.data = ajax_response.data;
                inventory_sources.date_fetched = current_timestamp;
                localStorage.setItem('inventory_sources', JSON.stringify(inventory_sources));
                filter(filters, inventory_sources.data);
            })
            .catch(function (error) {
                response({});
            });
    }

    function filter(filters, data) {
        var new_data = {}
        if (filters == "can_deliver") {
            for (var key in data) {
                if (data[key].can_deliver == 1) {
                    new_data[key] = data[key];
                }
            }
        } else {
            new_data = data;
        }
        response(new_data);
    }
}

export function getProducts(response) {
    var fetch_data = true;
    var current_timestamp = Math.round((new Date()).getTime() / 1000);
    if (localStorage.getItem('products') !== null) {
        var inventory_sources = JSON.parse(localStorage.getItem('products'));
        if (inventory_sources.date_fetched > (current_timestamp - (60 * 60 * 24))) {
            fetch_data = false;
            response(inventory_sources.data);
        }
    }
    if (fetch_data) {
        axios.defaults.withCredentials = true;
        axios.get(`${API_ROOT}/products/`, {withCredentials: true})
            .then(function (ajax_response) {
                var inventory_sources = {};
                inventory_sources.data = {};
                inventory_sources.data = ajax_response.data;
                inventory_sources.date_fetched = current_timestamp;
                localStorage.setItem('products', JSON.stringify(inventory_sources));
                response(inventory_sources.data);
            })
            .catch(function (error) {
                response({});
            });
    }
}
