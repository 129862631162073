import React, {Component} from 'react';
import pubsub from 'pubsub-js';
import {Collapse} from 'reactstrap';

class SidebarUserBlock extends Component {

    constructor(props) {
        super(props);
    }

    state = {
        userBlockCollapse: true
    }

    componentDidMount() {
        this.pubsub_token = pubsub.subscribe('toggleUserblock', () => {
            this.setState({
                userBlockCollapse: !this.state.userBlockCollapse
            });
        });
    }

    componentWillUnmount() {
        pubsub.unsubscribe(this.pubsub_token);
    }

    signMeOut() {
        this.props.userSignOut()
    }

    render() {

        return (
            <Collapse id="user-block" isOpen={this.state.userBlockCollapse}>
                <div>
                    <div className="item user-block">
                        {/* Name and Job */}
                        <div className="user-block-info">
                            <span className="user-block-name">{this.props.first_name} {this.props.last_name}</span>
                            <span className="user-block-role"><a onClick={this.signMeOut.bind(this)}>Sign Out</a></span>
                        </div>
                    </div>
                </div>
            </Collapse>
        )
    }
}

export default SidebarUserBlock;
