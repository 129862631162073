import React from 'react';
import {Link} from 'react-router-dom';
import ContentWrapper from '../Layout/ContentWrapper';
import {Row, Col, Button} from 'reactstrap';
import Swal from 'sweetalert2'
import axios from 'axios';
import {API_ROOT} from '../../api-config';
import moment from 'moment';
import 'moment-timezone';

moment.tz.setDefault("America/Toronto");

class Dashboard extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            events: [],
            todos: [],
            loading_events: true,
            loading_todo: true
        };
        this.goToCall = this.goToCall.bind(this);
    }

    componentDidMount() {
        if (localStorage.getItem('role_id') < 9) {
            console.log(localStorage.getItem('role_id'))
            var self = this;
            axios.get(API_ROOT + '/call_notes/summary?type=null&rep=' + localStorage.getItem('staff_id') + '&city=All&min_date=' + moment.utc().format("YYYY-MM-DD HH:mm:ss"))
                .then(function (response) {
                    self.setState({
                        events: response.data,
                        loading_events: false
                    });
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                        self.setState({
                            loading_events: false
                        });
                    }
                });
            axios.get(API_ROOT + '/todos?rep=' + localStorage.getItem('staff_id') + '&completed=0')
                .then(function (response) {
                    self.setState({
                        todos: response.data,
                        loading_todo: false
                    });
                })
                .catch(function (error) {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                        self.setState({
                            loading_todo: false
                        });
                    }
                });
        }
    }

    componentDidUpdate(prevProps) {
        // Typical usage (don't forget to compare props):
        console.log('triggered')
    }

    goToCall = (account_id, call_id) => {
        this.props.history.push("/account/" + account_id + "/call/" + call_id);
    };

    render() {
        return (
            <ContentWrapper>
                <div className="content-heading">
                    <div>
                        Dashboard
                    </div>
                </div>
                <Row>
                    <div className="col-lg-6">
                        <div className={(this.state.loading_events ? "card card-default whirl traditional" : "card card-default")}>
                            <div className="card-body">
                                <div className="lead bb">
                                    Calendar Preview
                                </div>
                                <div>
                                    {Object.entries(this.state.events).map(([key, event]) => {
                                        return (
                                            <div className="row border-bottom m-1" key={key} onClick={this.goToCall.bind(this, event.account_id, event.id)}>
                                                <div className="col-5">
                                                    {moment(event.start).format("ddd MMM DD H:mm A")}
                                                </div>
                                                <div className="col">
                                                    {event.title}
                                                    <div className="btn btn-primary btn-xs float-right">
                                                        <i className="far fa-share-square"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className={(this.state.events.length > 0 ? "d-none" : "pb-2 text-danger")}>
                                        No upcoming calendar entries
                                    </div>
                                    <Link to="../schedule/calendar">
                                        <div className="btn btn-primary btn-sm">View Full Calendar</div>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className={(this.state.loading_todo ? "card card-default whirl traditional" : "card card-default")}>
                            <div className="card-body">
                                <div className="lead bb">
                                    To Do List
                                </div>
                                <div>
                                    {Object.entries(this.state.todos).map(([key, event]) => {
                                        return (
                                            <div className="row border-bottom m-1" key={key} onClick={this.goToCall.bind(this, event.account_id, event.call_id)}>
                                                <div className={(moment().diff(moment(event.date)) < 0 ? "col-5 " : "col-5 text-danger")}>
                                                    {moment(event.date).format("ddd MMM DD H:mm A")}
                                                </div>
                                                <div className="col">
                                                    <span className="btn btn-primary btn-xs float-right">
                                                        <i className="far fa-share-square"></i>
                                                    </span>
                                                    {event.name}<br/><i>{event.title}</i>
                                                </div>
                                            </div>
                                        )
                                    })}
                                    <div className={(this.state.todos.length > 0 ? "d-none" : "pb-2 text-danger")}>
                                        No to do entries
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Row>
            </ContentWrapper>
        );
    }
}

export default (Dashboard);