import React, {Component} from 'react';
import {
    Button,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input
} from 'reactstrap';
import 'react-dropzone-uploader/dist/styles.css'

import axios from 'axios';
import {API_ROOT} from '../../api-config';
import Swal from 'sweetalert2'

class EditStaff extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            staff_id: props.staff.staff_id,
            first_name: props.staff.first_name,
            last_name: props.staff.last_name,
            companies: props.staff.companies,
            permissions: props.staff.permissions,
            inventory_source_id: props.staff.inventory_source_id,
            email: props.staff.email,
            password: "",
            permissions_list: props.permissions,
            companies_list: props.companies,
            status: props.staff.status,
            activate_inventory: false,
            processing: false,
            loading: true
        };
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        });
    };

    updateField = (name, value) => {
        if (value === true) {
            value = 1
        } else if (value === false) {
            value = 0
        } else if (typeof value != "string") {
            value = value.target.value;
        }
        this.setState({
            [name]: value
        }, () => {
            if (name != "password") {
                this.saveStaff()
            }
        });
    };

    toggleValue = (name, value) => {
        if (name === "permissions") {
            if (value == "ALL") {
                if (this.state.permissions != null && this.state.permissions == 'ALL') {
                    var permissions = '';
                } else {
                    var permissions = 'ALL';
                }
            } else {
                var permissions = ',' + this.state.permissions;
                if (this.state.permissions != null && this.state.permissions.indexOf(',' + value + ',') != -1) {
                    permissions = permissions.replace(',' + value + ',', ',');
                } else {
                    permissions += ',' + value + ',';
                }
            }
            this.setState({
                "permissions": permissions.replace(',,', ',')
            }, this.saveStaff);
        } else if (name === "companies") {
            if (value == "ALL") {
                if (this.state.companies != null && this.state.companies == 'ALL') {
                    var companies = '';
                } else {
                    var companies = 'ALL';
                }
            } else {
                var companies = ',' + this.state.companies;
                if (this.state.companies != null && this.state.companies.indexOf(',' + value + ',') != -1) {
                    companies = companies.replace(',' + value + ',', ',');
                } else {
                    companies += ',' + value + ',';
                }
            }
            this.setState({
                "companies": companies.replace(',,', ',')
            }, this.saveStaff);
        }
    };

    addInventory = () => {
        localStorage.removeItem('inventory_sources');
        this.setState({
            inventory_source_id: 1,
            activate_inventory: true
        }, this.saveStaff);
    }
    saveStaff = () => {
        this.setState({
            processing: true
        });
        var self = this;
        axios.defaults.withCredentials = true;
        var state = this.state;
        axios.put(API_ROOT + '/staff/' + this.state.staff_id, state)
            .then(function (response) {
                if (typeof self.props.loadStaff == "function") {
                    self.props.loadStaff();
                }
                self.setState({
                    processing: false,
                    activate_inventory: false
                });
                if (self.state.password != "") {
                    self.setState({
                        modal: false,
                        password: ""
                    });
                    Swal("Success!", response.data.Message, "success");
                }
            })
            .catch(function (error) {
                if (typeof error.response != "undefined") {
                    if (error.response.status === 401) {
                        self.props.userSignOut()
                    } else {
                        Swal("Error", error.response.data.Message, "error");
                        self.setState({
                            processing: false
                        });
                    }
                }
            });
    };

    render() {
        return (
            <FormGroup>
                <Button color="primary" size="xs" onClick={this.toggle}>{this.state.first_name} {this.state.last_name}</Button>
                <Modal isOpen={this.state.modal} toggle={this.toggle} size="md">
                    <ModalHeader>
                        <div className="h3 mb-0">Edit Staff</div>
                    </ModalHeader>
                    <ModalBody className="card card-default">
                        <Form>
                            <FormGroup row>
                                <Label sm={4}>First Name*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="first_name" value={this.state.first_name} onChange={(event) => this.updateField("first_name", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Last Name*</Label>
                                <Col sm={8}>
                                    <Input type="text" name="last_name" value={this.state.last_name} onChange={(event) => this.updateField("last_name", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Email*</Label>
                                <Col sm={8}>
                                    <Input type="email" name="email" value={this.state.email} onChange={(event) => this.updateField("email", event)}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4}>Open/Active</Label>
                                <Col sm={8} className="pl-5">
                                    <Input type="checkbox" name="status" value="1" checked={(this.state.status == 1 || this.state.status === true ? true : false)}
                                           onChange={(event) => this.updateField("status", (this.state.status == 1 || this.state.status === true ? false : true))}/>
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4} className="pt-0">Companies</Label>
                                <Col sm={8} className="pl-5">
                                    <div>
                                        <Label className="mb-0 text-bold">
                                            <Input type="checkbox" name="status" value="1"
                                                   checked={(this.state.companies != null && this.state.companies.indexOf('ALL') != -1 ? true : false)}
                                                   onChange={(event) => this.toggleValue("companies", "ALL")}/>
                                            - ALL -
                                        </Label>
                                    </div>
                                    {Object.keys(this.state.companies_list).map(function (company_id) {
                                        return (
                                            <div>
                                                <Label className={(this.state.companies == "ALL" ? "mb-0 text-muted" : "mb-0")}>
                                                    <Input type="checkbox" name="status" value="1"
                                                           checked={(this.state.companies != null && (this.state.companies == "ALL" || this.state.companies.indexOf(',' + company_id + ',') != -1) ? true : false)}
                                                           disabled={(this.state.companies == "ALL" ? true : false)}
                                                           onChange={(event) => this.toggleValue("companies", company_id)}/>
                                                    {this.state.companies_list[company_id].name}
                                                </Label>
                                            </div>
                                        )
                                    }, this)}
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label sm={4} className="pt-0">Permissions</Label>
                                <Col sm={8} className="pl-5">
                                    <div>
                                        <Label className="mb-0 text-bold">
                                            <Input type="checkbox" name="status" value="1"
                                                   checked={(this.state.permissions != null && this.state.permissions.indexOf('ALL') != -1 ? true : false)}
                                                   onChange={(event) => this.toggleValue("permissions", "ALL")}/>
                                            - ALL -
                                        </Label>
                                    </div>
                                    {this.state.permissions_list.map(function (permission) {
                                        return (
                                            <div>
                                                <Label className={(this.state.permissions == "ALL" ? "mb-0 text-muted" : "mb-0")}>
                                                    <Input type="checkbox" name="status" value="1"
                                                           checked={(this.state.permissions != null && (this.state.permissions == "ALL" || this.state.permissions.indexOf(',' + permission.code + ',') != -1) ? true : false)}
                                                           disabled={(this.state.permissions == "ALL" ? true : false)}
                                                           onChange={(event) => this.toggleValue("permissions", permission.code)}/>
                                                    {permission.name}
                                                </Label>
                                            </div>
                                        )
                                    }, this)}
                                </Col>
                            </FormGroup>
                            <div className="border-top pb-2"></div>
                            <FormGroup row>
                                <Label sm={4}>NEW Password <span className="text-muted">(opt.)</span></Label>
                                <Col sm={8}>
                                    <Input type="text" name="password" value={this.state.password} onChange={(event) => this.updateField("password", event)}/>
                                    <Button color="primary" onClick={this.saveStaff}><i className={(this.state.processing ? "fa fa-spinner fa-spin mr-2" : "d-none")}></i>Save
                                        Password</Button>
                                </Col>
                            </FormGroup>
                            <div className="border-top pb-2"></div>
                            <FormGroup row>
                                <Label sm={4}>Allow Inventory Handling <span className="text-muted">(opt.)</span></Label>
                                <Col sm={8} className="pt-2">
                                    <div className={(this.state.inventory_source_id > 0 ? "text-success text-bold" : "d-none")}><i className="fas fa-check"></i> Activated</div>
                                    <div className={(this.state.inventory_source_id > 0 ? "d-none" : "")}>
                                        <Button color="primary" onClick={this.addInventory}>
                                            Activate
                                        </Button>
                                    </div>
                                    <small className="text-muted">(Allows employees to deliver product and enter product pickup requests)</small>
                                </Col>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </FormGroup>
        );
    };
}

export default (EditStaff);
